import * as React from "react";

import { Layout, Subtitle, Title } from "../components/index";

const NotFoundPage = (props) => {
    return (
        <Layout title="Page Not Found" {...props}>
            <Title />
            <Subtitle>Sorry, the page you requested was not found.</Subtitle>
        </Layout>
    );
};

export default NotFoundPage;
